import 'ol/ol.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React from 'react';
import MapOL from 'ol/Map';
import VectorTileLayer from "ol/layer/VectorTile";
import {applyStyle, stylefunction} from "ol-mapbox-style";
import {Feature, Overlay, View} from "ol";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Fill, Stroke, Style, Circle} from "ol/style";
import {fromLonLat, toLonLat} from "ol/proj";
import {GeoJSON} from "ol/format";
import {ApolloClient, gql, InMemoryCache} from "@apollo/client";
import {toStringHDMS} from "ol/coordinate";
import {Popover} from 'bootstrap';
import {Point} from "ol/geom";
import {sector, point} from '@turf/turf';
import TileLayer from "ol/layer/Tile";
import {OSM} from "ol/source";
import ReactPlayer from "react-player";
import {defaults as defaultControls, MousePosition} from "ol/control";
import {createRoot} from "react-dom/client";
import {Pannellum} from 'pannellum-react';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.mapContainerRef = React.createRef();
        this.mapRef = React.createRef();
        this.popupRef = React.createRef();
        this.state = {
            url: 'https://www.youtube.com/watch?v=PE9TWvoOlj8',
            title: '',
            text: '',
            place: '',
            number: '',
        }
    }

    componentDidMount() {
        const vOSM = new VectorTileLayer({renderMode: 'vector', declutter: true});
        applyStyle(vOSM, 'https://osm.ivazh.ru/styles/basic/style.json');

        const source = new VectorSource();

        const vector = new VectorLayer({
            source: source,
            declutter: false,
        });
        applyStyle(vector, 'cam_style.json').then(() => {
            console.log('Styles initialiazed');
        });

        const mp = new MousePosition({
            coordinateFormat: (p) => {
                return toStringHDMS(toLonLat(p));
            },
        });

        const map = new MapOL({
            controls: defaultControls().extend([mp]),
            target: this.mapContainerRef.current,
            layers: [
                //vOSM,
                new TileLayer({
                    source: new OSM(),
                    opacity: 0.5,
                }),
                vector,
            ],
            view: new View({
                zoom: 15,
                center: fromLonLat([30.313684596019296, 59.940426111007014]),
            }),
        });
        this.mapRef.current = map;

        let attributes = {};
        const client = new ApolloClient({
            cache: new InMemoryCache(),
            uri: 'https://camapi.ivazh.ru/graphql'
        });
        client.query({
            query: gql`
            query load
            {
                getStructure {
                  features
                }
            }`,
        }).then((res) => {
            const data = res.data.getStructure.features;
            data.attributes.forEach((a) => {
                attributes[a.name] = a.alias;
            });
        });
        client.query({
            query: gql`
            query load($code: String!)
            {
                loadAllFeatures(code: $code) {
                  features
                }
            }`,
            variables: {
                code: '01'
            }
        }).then((res) => {
            const geojson = res.data.loadAllFeatures.features;
            const gj = new GeoJSON({dataProjection: 'EPSG:4326'});
            const fs = gj.readFeatures(geojson, {featureProjection: 'EPSG:3857'});
            let sectors = fs.map((f) => {
                const center = point(toLonLat(f.getGeometry().getCoordinates()));
                const ang1 = f.get('direction') - 0.5 * f.get('angle');
                const ang2 = f.get('direction') + 0.5 * f.get('angle');
                const s = sector(center, f.get('distance'), 90-ang2, 90-ang1, {units: 'meters'});
                const sf = gj.readFeature(s, {featureProjection: 'EPSG:3857'});
                sf.setProperties({layer: 'camera_zona'});
                return sf;
            });
            fs.forEach((f) => {
                f.setProperties({layer: 'camera'});
            });
            source.addFeatures(fs);
            source.addFeatures(sectors);
        });
        client.query({
            query: gql`
            query load($code: String!)
            {
                loadAllFeatures(code: $code) {
                  features
                }
            }`,
            variables: {
                code: '02'
            }
        }).then((res) => {
            const geojson = res.data.loadAllFeatures.features;
            const fs = new GeoJSON().readFeatures(geojson, {
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857'
            });
            fs.forEach((f) => {
                f.setProperties({layer: 'sensor'});
            });
            source.addFeatures(fs);
        });
        client.query({
            query: gql`
            query load($code: String!)
            {
                loadAllFeatures(code: $code) {
                  features
                }
            }`,
            variables: {
                code: '03'
            }
        }).then((res) => {
            const geojson = res.data.loadAllFeatures.features;
            const fs = new GeoJSON().readFeatures(geojson, {
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857'
            });
            fs.forEach((f) => {
                f.setProperties({layer: 'panorama'});
            });
            source.addFeatures(fs);
        });

        const popup = new Overlay({
            element: document.getElementById('popup'),
            autoPan: true,
        });
        map.addOverlay(popup);

        const element = popup.getElement();

        let popupShowed = false;

        map.on('click', (e) => {
            let popover = Popover.getInstance(element);
            if (popover) {
                popover.dispose();
                popupShowed = false;
            }
            const features = map.getFeaturesAtPixel(e.pixel, {
                layerFilter: l => l === vector,
                hitTolerance: 6,
            }).filter((f) => f.getGeometry() instanceof Point);
            if (!features.length)
                return;
            const feature = features[0];
            const g = feature.getGeometry();
            if (!(g instanceof Point))
                return;
            const coordinate = g.getCoordinates();
            const properties = feature.getProperties();
            const hdms = toStringHDMS(toLonLat(coordinate));
            const name = properties.name ?? 'Датчик';
            const div = document.createElement('div');
            const root = createRoot(div);

            if (properties.layer === 'camera') {
                root.render(
                    // <Popover placement={'auto'}>
                    //     <Popover.Header as="h3">{name}</Popover.Header>
                    //     <Popover.Body>
                    <>
                            <p>Место установки: <code>{properties.place}</code><br/>
                                Номер: <code>{properties.number}</code></p>
                            <ReactPlayer width={'684px'} playing url={properties.url}/>
                    </>
                    //     </Popover.Body>
                    // </Popover>
                );
            } else if (properties.layer === 'sensor') {
                root.render(
                    // <Popover placement={'auto'}>
                    //     <Popover.Header as="h3">{name}</Popover.Header>
                    //     <Popover.Body>
                            <p>Номер: <code>{properties.number}</code></p>
                    //     </Popover.Body>
                    // </Popover>
                );
            } else {
                const ang = properties.url === 'pano.jpg' ? 40 : 180;
                root.render(
                    // <Popover placement={'auto'}>
                    //     <Popover.Header as="h3">{name}</Popover.Header>
                    //     <Popover.Body>
                    <>
                            <p>Описание: <code>{properties.descr}</code></p>
                            <Pannellum image={`./${properties.url}`} autoLoad vaov={ang}>
                            </Pannellum>
                    </>
                    //     </Popover.Body>
                    // </Popover>
                );
            }
            popup.setPosition(coordinate);
            popover = new Popover(element, {
                animation: true,
                container: element,
                sanitize: false,
                content: div,
                html: true,
                placement: 'auto',
                title: name,
                trigger: 'focus',
            });
            popover.show();
            popupShowed = true;
        });

        map.on('pointermove', (e) => {
            if (popupShowed)
                return;
            const features = map.getFeaturesAtPixel(e.pixel, {
                layerFilter: l => l === vector,
                hitTolerance: 6,
            }).filter((f) => f.getGeometry() instanceof Point);
            if (!features.length) {
                let popover = Popover.getInstance(element);
                if (popover) {
                    popover.dispose();
                    popupShowed = false;
                }
                return;
            }
            const feature = features[0];
            const g = feature.getGeometry();
            if (!(g instanceof Point))
                return;
            let popover = Popover.getInstance(element);
            if (popover)
                return;
            const coordinate = g.getCoordinates();
            popup.setPosition(coordinate);
            const properties = feature.getProperties();
            const name = properties.name ?? 'Датчик';
            let data = '';
            for (const key in properties)
                if (key in attributes)
                    data += `${attributes[key]}: <code>${JSON.stringify(properties[key])}</code><br/>`;
            popover = new Popover(element, {
                animation: true,
                container: element,
                sanitize: false,
                content: `<p>${data}</p>`,
                html: true,
                placement: 'auto',
                title: name,
                customClass: 'small-popover'
            });
            popover.show();
        });
    }

    render() {
        return (
            <>
                <div ref={this.mapContainerRef} className="App">
                </div>
                <div style={{display: 'none'}}>
                    <div id={'popup'}></div>
                    <div id={'attrPopup'}></div>
                </div>
            </>
        );
    }
}

export default App;
